<template>
  <v-container class="contenedor-main">
    <v-row>
      <tool-bar-nav-list
        :items="[{ items: listaPeriodos, value: busquedaPe, label: 'Periodo' }]"
        v-on:toggle="searchG($event)"
      ></tool-bar-nav-list>
    </v-row>
    <v-row>
      <tool-bar-nav
        v-if="mostrarTable == true"
        :pageRow="{ value: pageLengthPer, type: 'gestionDHeigth' }"
        :editColsBottom="{ badgeText: 'Editar cols' }"
        :dataColumns="{
          colsShow: columnasMostrar,
          colsList: listaColumnas,
          endpointEdit: 'gestiones',
          materia: null,
          periodo: null,
        }"
        :refreshBottom="{ badgeText: 'Reestablecer cols' }"
        :copyRowBottom="{ badgeText: 'Copiar venta' }"
        :exportBottom="{
          badgeText: 'Exportar tabla',
          nameDoc: 'Gestiones',
        }"
        :histBottom="{ show: rigthClick, data: historial }"
        :colorBottom="{ show: rigthClick }"
        :textoActionToolBar="{ text: textoActionToolBar }"
        :selectedRow="onSelected"
        :grid="{ idGrid: $refs.grid }"
        v-on:toggle="eventosGestiones($event)"
        v-on:texto-tool="eventosTextTool($event)"
        v-on:color-item="eventosColor($event, asignarColor)"
      >
      </tool-bar-nav>
      <v-container class="contenedor-tabla" v-show="mostrarTable == true">
        <vue-excel-editor
          ref="grid"
          v-if="mt"
          v-show="mostrarTable == true"
          v-model="jsondata"
          :page="pageLengthPer"
          filter-row
          :localized-label="myLabels"
          remember
          @select="onSelect"
          :enterToSouth="enterAbajo"
          v-on:toggle-table="activeRigthClick($event)"
          @cell-focus="getLabelColor"
          :cell-style="metodoColorBack"
        >
          <vue-excel-column
            field="Línea"
            label="Línea"
            :validate="valid"
            type="number"
          />
          <vue-excel-column
            field="Materia"
            label="Materia"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="Nombre de usuario"
            label="Nombre de usuario"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="Contraseña"
            label="Contraseña"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="Contraseña correo"
            label="Contraseña correo"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="Correo"
            label="Correo"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="País"
            label="País"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="Servidor"
            label="Servidor"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="Gestión requerida"
            label="Gestión requerida"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="Fecha de solicitud"
            label="Fecha de solicitud"
            :validate="valid"
            type="number"
          />
          <vue-excel-column
            field="Estado de la gestión"
            label="Estado de la gestión"
            type="select"
            :options="estadoG"
            :change="cambioEstadoG"
          />
          <vue-excel-column
            field="Notas"
            label="Notas"
            :validate="valid"
            type="string"
          />
          <vue-excel-column
            field="Notas docente"
            label="Notas docente"
            :change="addNotaDoc"
          />
          <vue-excel-column
            field="Soporte"
            label="Soporte"
            :change="openLinkS"
            :options="['Ver soportes']"
          />
          <vue-excel-column
            field="Evidencias"
            label="Evidencias"
            :change="openEvidencias"
            :options="['Cargar evidencias']"
          />
          <vue-excel-column
            field="Tipo de gestión"
            label="Tipo de gestión"
            type="number"
            :validate="valid"
          />
        </vue-excel-editor>
        <v-dialog v-model="dialog" v-if="dialog" scrollable max-width="50%">
          <!-- EVIDENCIAS - DOC-->
          <crud-archivos
            v-on:toggle="toggleArchivos($emit)"
            :title="'Ver los archivos cargados'"
            :subtitle="'Adjuntar archivos de evidencia'"
            :textVer="'Ver link '"
            :ventaId="id"
            :listData="listaLink"
            :cargar="true"
            :eliminar="true"
            tipo="gestionE"
            :info="gestion"
          >
          </crud-archivos>
        </v-dialog>
        <!-- SOPORTES - ADMIN -->
        <v-dialog v-if="dialogS" v-model="dialogS" scrollable max-width="50%">
          <crud-archivos
            v-on:toggle="toggleArchivos($emit)"
            :title="'Ver los archivos cargados por el administrador'"
            :subtitle="'* Estos documentos solo se pueden ver'"
            :textVer="'Ver link '"
            :ventaId="ventaId"
            :listData="listaLinkS"
            :cargar="false"
            :eliminar="false"
            tipo="ver"
            :info="null"
          >
          </crud-archivos>
        </v-dialog>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { host } from "./Tools/variables";
import axios from "axios";
import ToolBarNavList from "./component/ToolBarNavList.vue";
import infoSelected from "./Tools/itemSelected";
import ToolBarNav from "./component/ToolBarNav.vue";
import data from "./Tools/data";
// import CrudArchivos from "./component/CrudArchivos.vue";

import tablesMixin from "../mixins/tablas";

export default {
  mixins: [tablesMixin],

  components: {
    ToolBarNavList,
    ToolBarNav,
    CrudArchivos: () =>
      import(
        /* webpackChunkName: "CrudArchivos"  */ "./component/CrudArchivos.vue"
      ),
    // CrudArchivos,
  },

  watch: {
    busquedaPe: function (v) {
      if (v != null) {
        this.initialize();
      } else {
        this.mostrarTable = false;
      }
    },
    dataEstadoG: function () {
      for (var f = 0; f < this.$store.state.estadoG.length; f++) {
        this.estadoG.push(this.$store.state.estadoG[f]["tipo"]);
      }
    },
  },

  data() {
    return {
      pageLengthPer: this.$store.state.gestionPHeigth,
      textoActionToolBar: [],
      dataEstadoG: [],
      listaCursos: [],
      listaEstudiantes: [],
      estudiante: null,
      curso: null,
      addGestion: false,
      listaPeriodos: [],
      columnasMostrar: [],
      listaColumnas: [],
      mostrarTable: false,
      busquedaPe: "",
      jsondata: [],
      mt: false,
      onSelected: null,
      dataLink: [],
      dataLinkS: [],
      listaLink: [],
      listaLinkS: [],
      dialog: false,
      dialogS: false,
      id: null,
      gestion: null,
      estadoG: [],
      // click derecho
      rigthClick: false,
      // color
      asignarColor: {
        name: null,
        color: null,
        idItem: null,
      },
      // tiene personalización de cols?
      haveCellsColor: true,
      // hitorial
      historial: {
        nombreCol: null,
        id: null,
      },
    };
  },

  async created() {
    this.periodosGet();
    this.cursosGet();
    if (this.$store.state.estadoG.length == 0) {
      this.dataEstadoG = await await data.dataList(
        "/estadoGestion",
        "lista",
        "listar",
        "estadoG"
      );
    } else {
      this.dataEstadoG = this.$store.state.estadoG;
    }
    await this.cargueColors();
  },

  mounted() {
    document.title = "Gestión Docente";
  },
  methods: {
    cursosGet() {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "cursos_habilitados");
          axios.post(host + "/cursos", fd).then((result) => {
            let data = result.data;
            this.filtroM = data.listaMaterias;
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    periodosGet() {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "cursos_habilitados");
          axios.post(host + "/cursos", fd).then((result) => {
            let data = result.data;
            this.listaPeriodos = data.listaPeriodos;
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    search(a) {
      if (a == "blur") {
        this.blur();
      } else {
        for (let y = 0; y < a.length; y++) {
          var element = a[y];
          if (element.label == "Materias") this.materia = element.value;
        }
        if (element.label == "Periodos") {
          this.periodo = element.value;
        }
      }
    },

    async initialize() {
      this.mostrarTable = false;
      this.listaColumnas = [];
      this.columnasMostrar = [];
      this.mt = false;
      await this.cargueColors();
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "listar");
          fd.append("periodo", this.busquedaPe);
          axios.post(host + "/gestiones", fd).then((result) => {
            let data = result.data.lista;
            this.buscando = false;
            if (result.data.text == "OK") {
              for (let i = 0, len = data.length; i < len; i++) {
                this.dataLink.push({
                  id: data[i]["id"],
                  data: data[i]["Evidencias"],
                });
                data[i]["Evidencias"] =
                  data[i]["Evidencias"] == "null" ? "-" : "";
                this.dataLinkS.push({
                  id: data[i]["id"],
                  data: data[i]["Soportes"],
                });
                data[i]["Soportes"] = data[i]["Soportes"] == "null" ? "-" : "";
                data[i]["Tipo de gestión"] =
                  data[i]["Tipo de gestión"] == "null"
                    ? "-"
                    : data[i]["Tipo de gestión"];
                data[i]["Notas"] =
                  data[i]["Notas"] == "null" ? "-" : data[i]["Notas"];
                data[i]["Notas docente"] =
                  data[i]["Notas docente"] == "null"
                    ? "-"
                    : data[i]["Notas docente"];
              }
              this.jsondata = data;
              this.mostrarTable = true;
              if (this.jsondata.length == 0) {
                this.$alert("No existen gestiones");
              } else {
                this.listaColumnas = result.data.visibilidad;
                const col = this.listaColumnas;
                for (var k = 0, len = col.length; k < len; k++) {
                  if (this.listaColumnas[k]["valor"] == true) {
                    this.columnasMostrar.push(this.listaColumnas[k]);
                  }
                }

                this.cargueCol();
              }
            } else {
              this.$alert(result.data.text);
            }
          });
          this.mt = true;
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    onSelect() {
      this.onSelected = infoSelected(this.$refs.grid, "id");
      if (this.onSelected != null) {
        this.onSelected["mensaje"] =
          this.onSelected["field"]["Usuario"] +
          " del curso " +
          this.onSelected["field"]["Materia"] +
          " - " +
          this.onSelected["field"]["Periodo"];
      }
      this.rigthClick = false;
    },

    openEvidencias(newVal, oldVal, row) {
      this.$refs.grid.inputBoxBlur();
      this.id = row.id;
      this.gestion = row;
      this.dialog = true;
      const resObject = this.dataLink.find((item) => item.id === row.id);
      this.listaLink = resObject.data;
      return false;
    },

    openLinkS(newVal, oldVal, row) {
      this.$refs.grid.inputBoxBlur();
      console.log(oldVal);
      this.dialogS = true;
      const resObject = this.dataLinkS.find((item) => item.id === row.id);
      this.listaLinkS = resObject.data;
      this.id = row.id;
      return false;
    },

    searchG(a) {
      if (a == "blur") {
        this.$refs.grid.inputBoxBlur();
      } else {
        var element = a[0];
        if (element.label == "Periodo") this.busquedaPe = element.value;
      }
    },

    cambioEstadoG(newVal, oldVal, row) {
      this.textoActionToolBar.push(
        "Guardando estado de gestión " + '"' + newVal + '"'
      );
      console.log(oldVal, row);
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "modificar");
          fd.append("gestionId", row.id);
          fd.append("estado", newVal.trim());
          fd.append("gestionRequerida", row["Gestión requerida"]);
          fd.append("notasDocente", row["Notas docente"]);
          fd.append("observaciones", row["Notas"]);
          fd.append("tipoGestion", row["Tipo de gestión"]);
          fd.append("evidencias", []);
          fd.append("soportes", []);
          axios.post(host + "/gestiones", fd).then((result) => {
            let data = result.data;
            var rem = this.textoActionToolBar.indexOf(
              "Guardando estado de gestión " + '"' + newVal + '"'
            );
            this.guardando = false;
            if (data.text == "OK") {
              this.textoActionToolBar.splice(rem, 1);
              return newVal;
            } else {
              this.$alert("Error al guardar la información de esta gestión");
              this.textoActionToolBar.splice(rem, 1);
              this.textoActionToolBar.push(
                "Error al guardar estado de gestión " + '"' + newVal + '"'
              );
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    addNotaDoc(newVal, oldVal, row) {
      console.log(oldVal, row);
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "modificar");
          fd.append("gestionId", row.id);
          fd.append("estado", row["Estado de la gestión"]);
          fd.append("gestionRequerida", row["Gestión requerida"]);
          fd.append("notasDocente", newVal.trim());
          fd.append("observaciones", row["Notas"]);
          fd.append("tipoGestion", row["Tipo de gestión"]);
          fd.append("evidencias", []);
          fd.append("soportes", []);
          axios.post(host + "/gestiones", fd).then((result) => {
            let data = result.data;
            this.guardando = false;
            if (data.text == "OK") {
              return newVal.trim();
            } else {
              this.$alert("Error al guardar la información de esta nota");
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    metodoColor(row, col) {
      console.log(row[["Estado de la gestión"]]);
      var rowItem = row["Estado de la gestión"].trim();
      if (rowItem == "NUEVA" && col.name == "Estado de la gestión") {
        return {
          "background-color": "#c27ba0",
        };
      }
      if (rowItem == "EN PROCESO" && col.name == "Estado de la gestión") {
        return {
          "background-color": "#ffff00",
        };
      }
      if (rowItem == "ENVIADA" && col.name == "Estado de la gestión") {
        return {
          "background-color": "#ff9900",
        };
      }
      if (rowItem == "RECIBIDA" && col.name == "Estado de la gestión") {
        return {
          "background-color": "#4285f4",
        };
      }
      if (rowItem == "CALIFICADA" && col.name == "Estado de la gestión") {
        return {
          "background-color": "#B695C0",
        };
      }
      if (rowItem == "NO RECIBIDA" && col.name == "Estado de la gestión") {
        return {
          "background-color": "#FF0000",
        };
      }
      if (rowItem == "NUNCA ENVIADA" && col.name == "Estado de la gestión") {
        return {
          "background-color": "#9900ff",
        };
      }
      if (rowItem == "ENVIADO A USER" && col.name == "Estado de la gestión") {
        return {
          "background-color": "#34a853",
        };
      }
      return {};
    },
    eventosGestion(s) {
      if (s == "close") {
        this.addGestion = false;
      }
    },

    eventosGestiones(d) {
      if (d == "refresh" || d == "edit") {
        this.initialize();
      }
      if (d == "clearText") {
        this.textoActionToolBar = [];
      }
    },

    toggleArchivos() {
      this.dialog = false;
      this.dialogS = false;
    },
  },
};
</script>


